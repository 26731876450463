import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Form, IFormProps } from '@hotelplan/components.common.forms';
import { DeviceType } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { isBrowser } from '@hotelplan/libs.utils';
import BannerSearchFormSkeleton from 'components/domain/BannerSearchFormSkeleton.skeleton';
import { EmptyResultsModal } from 'components/domain/EmptyResults';
import HomeSearchControlForm from 'components/domain/home/HomeSearchControlForm';
import { SearchControlInputsSkeleton } from 'components/domain/searchControl/search-control-skeleton';
import {
  mapSearchControlToCriteria,
  mapSearchControlValuesToFormState,
} from 'components/domain/searchControl/SearchControl.mappers';
import type { ISearchControlState } from 'components/domain/searchControl/SearchControl.types';
import SearchControlTravelTypeContainer from 'components/domain/searchControl/SearchControlTravelTypeContainer';
import useSearchControlForm from 'components/domain/searchControl/useSearchControlForm';
import {
  GetFlightSearchControlValuesDocument,
  GetFlightSearchControlValuesQuery,
  GetFlightSearchControlValuesQueryVariables,
} from 'graphql/flight/GetFlightSearchControlValues.generated';
import {
  GetHomeLinkDocument,
  GetHomeLinkQuery,
  GetHomeLinkQueryVariables,
} from 'graphql/home/GetHomeLink.generated';

const FlightForm = styled(Form)(
  ({ theme }) => theme.forms.bannerForm
) as React.FC<IFormProps<ISearchControlState>>;

const FlightSearchForm: React.FC<{
  postSubmit?: (state: ISearchControlState) => void;
}> = ({ postSubmit }) => {
  const { t } = useTranslation('results');
  const { mobile } = useDeviceType();
  const deviceType = mobile ? DeviceType.Mobile : DeviceType.Desktop;

  const {
    initialValues,
    loading,
    defaultLoading,
    formApiRef,
    onChange,
    onApply,
    noOrlModal,
  } = useSearchControlForm<
    GetFlightSearchControlValuesQuery,
    GetFlightSearchControlValuesQueryVariables,
    GetHomeLinkQuery,
    GetHomeLinkQueryVariables
  >(
    {
      query: GetFlightSearchControlValuesDocument,
      variablesMapper: travelType => ({ deviceType, travelType }),
      dataMapper: data =>
        mapSearchControlValuesToFormState(data.flightHome.searchControl),
    },
    {
      query: GetHomeLinkDocument,
      variablesMapper: formState => ({
        homeSearchControlCriteriaInput: mapSearchControlToCriteria(formState),
        deviceType,
      }),
      dataMapper: linkData => linkData.home.link,
    }
  );

  if (!initialValues || defaultLoading || !isBrowser)
    return <BannerSearchFormSkeleton />;

  return initialValues ? (
    <>
      <EmptyResultsModal
        isModalShowed={noOrlModal.isModalShowed}
        onRenewSearch={noOrlModal.onRenewSearch}
        onClose={noOrlModal.onClose}
        title={t('noOrlModal.title')}
        description={t('noOrlModal.description', {
          productName: noOrlModal.productName,
        })}
        buttonContent={t('noOrlModal.buttonContent')}
      />
      <FlightForm
        formApiRef={formApiRef}
        initialValues={initialValues}
        onSubmit={nextFormState => {
          onApply(nextFormState);
          postSubmit?.(nextFormState);
        }}
        onChange={onChange}
      >
        <SearchControlTravelTypeContainer>
          {loading ? (
            <SearchControlInputsSkeleton />
          ) : (
            <HomeSearchControlForm />
          )}
        </SearchControlTravelTypeContainer>
      </FlightForm>
    </>
  ) : null;
};

export default FlightSearchForm;
