import type { NextPage } from 'next';
import * as React from 'react';
import styled from 'styled-components';
import { BannerFormWrap } from '@hotelplan/components.common.banner-form';
import { Hero } from '@hotelplan/components.common.hero';
import { PageType } from '@hotelplan/graphql.types';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { ServerSideUnit } from '@hotelplan/libs.ssp-units';
import { trackSearch } from '@hotelplan/libs.tracking';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Container from 'components/domain/container/Container';
import { DynamicComponents } from 'components/domain/dynamic-components';
import FlightSearchForm from 'components/domain/flight/FlightSearchForm';
import HomeDynamicComponentsSkeleton from 'components/domain/home/HomeDynamicComponents.skeleton';
import { mapSearchControlStateToTrackableData } from 'components/domain/searchControl/SearchControl.mappers';
import StyledTextBlock from 'components/domain/StyledTextBlock';
import { createPageServerFn } from 'config/pageUnitsConfig';
import {
  GetFlightHomeMetaDataDocument,
  GetFlightHomeMetaDataQuery,
  GetFlightHomeMetaDataQueryVariables,
} from 'graphql/flight/GetFlightHomeMetaData.generated';
import {
  GetFlightHomeStaticComponentsDocument,
  GetFlightHomeStaticComponentsQuery,
  GetFlightHomeStaticComponentsQueryVariables,
} from 'graphql/flight/GetFlightHomeStaticComponents.generated';
import { TextBlockInfoStylesFragment } from 'graphql/fragments/TextBlockInfoStyles.generated';
import type { ResizedImageWithMetaFragment } from 'graphql/image/ResizedImageWithMeta.generated';
import type { TextComponentFragment } from 'graphql/text/TextComponent.generated';

const FlightPageText = styled(StyledTextBlock)(
  sx2CssThemeFn({
    px: [4, 0],
    mb: [4, '50px'],
  })
);

const FlightPageContent = styled(Container)(
  sx2CssThemeFn({
    pt: [6, '60px'],
  })
);

interface IFlightPageProps {
  hero?: ResizedImageWithMetaFragment;
  info?: TextComponentFragment & TextBlockInfoStylesFragment;
}

const FlightPage: NextPage<IFlightPageProps> = props => {
  const { hero, info } = props;
  return (
    <>
      <BannerFormWrap>
        {hero && <Hero image={hero} />}
        <FlightSearchForm
          postSubmit={state =>
            trackSearch({
              searchControl: mapSearchControlStateToTrackableData(state),
            })
          }
        />
      </BannerFormWrap>
      <FlightPageContent>
        {info && (
          <FlightPageText
            title={info.title}
            text={info.text}
            backgroundColor={info.backgroundColor}
            fontColor={info.fontColor}
            backgroundImage={info.backgroundImage}
          />
        )}
      </FlightPageContent>
      <DynamicComponents placeholder={<HomeDynamicComponentsSkeleton />} />
    </>
  );
};

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.FlightHome,
    pageEventType: `flights`,
    namespaceRequired: [`common`, `flight`, `forms`],
  },
  [
    ServerSideUnit.createServerSideUnit(
      [apolloReqCtxUnitRef],
      async function getFlightPageUnit(ctx, { queryCtx }) {
        const [
          { data: dataMeta = null },
          { data: dataContent },
        ] = await Promise.all([
          queryCtx<
            GetFlightHomeMetaDataQuery,
            GetFlightHomeMetaDataQueryVariables
          >({ query: GetFlightHomeMetaDataDocument }),
          queryCtx<
            GetFlightHomeStaticComponentsQuery,
            GetFlightHomeStaticComponentsQueryVariables
          >({ query: GetFlightHomeStaticComponentsDocument }),
        ]);

        return {
          props: {
            meta: dataMeta.flightHome.meta,
            ...dataContent.flightHome,
          },
        };
      }
    ),
  ] as any
);

export default FlightPage;
